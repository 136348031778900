import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { BiLock } from "react-icons/bi";
import { toast } from "sonner";
import usePayment from "../../hook/usePayment";
import { getCookie } from "../../Utils/cookie";

const CardPaymentView = ({ goToNextSlide, currentPlan }) => {
  const { _id: planId } = currentPlan;
  const stripe = useStripe();
  const elements = useElements();
  const { createStripeSubscription, setPaymentLoading, paymentLoading } =
    usePayment();

  const [completedFields, setCompletedFields] = useState(new Set());
  const [error, setError] = useState("");

  const handleElementChange = (elementType, event) => {
    setError(event.error ? event.error.message : "");

    setCompletedFields((prevFields) => {
      const updatedFields = new Set(prevFields);
      if (event.empty) {
        updatedFields.delete(elementType);
      } else {
        updatedFields.add(elementType);
      }
      return updatedFields;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    if (!stripe || !elements) {
      toast.error("Payment system is not loaded. Please try again later.");
      setPaymentLoading(false);
      return;
    }

    try {
      const cardElement = elements.getElement(CardNumberElement);

      let updatedData = getCookie("data");

      // Step 1: Create Payment Method
      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

      if (paymentMethodError) {
        throw new Error(paymentMethodError.message);
      }

      const paymentMethodId = paymentMethod.id;

      // Step 2: Create Subscription on the Server
      const subscriptionResponse = await createStripeSubscription({
        isRecurring: true,
        paymentMethodId,
        planId,
      });

      if (!subscriptionResponse || subscriptionResponse.status !== 200) {
        throw new Error("Subscription creation failed. Please try again.");
      }

      const paymentIntentClientSecret =
        subscriptionResponse.data?.latest_invoice?.payment_intent
          ?.client_secret;

      if (!paymentIntentClientSecret) {
        throw new Error("Missing client secret for payment confirmation.");
      }

      // Step 3: Confirm Payment Intent on the Client Side
      const { error: confirmError } = await stripe.confirmCardPayment(
        paymentIntentClientSecret
      );

      if (confirmError) {
        throw new Error(confirmError.message);
      }

      toast.success("Payment successful!");
      // console.log("Payment successful!");

      setTimeout(() => goToNextSlide(updatedData), 1500);
    } catch (error) {
      console.error("Error during payment process:", error);
      toast.error(
        error.message || "An unexpected error occurred. Please try again."
      );
    } finally {
      setPaymentLoading(false);
    }
  };

  const elementOptions = {
    style: {
      base: {
        fontSize: "16px",
        padding: "20px",
        border: "1px solid #999999",
        borderRadius: "8px",
        height: "60px",
        width: "100%",
        "::placeholder": {
          color: "#999999",
        },
      },
      invalid: {
        color: "#9e2146",
        border: "1px solid #9e2146",
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack position={"relative"} mt={2}>
        <Stack>
          <Typography
            variant="caption"
            sx={{ fontSize: "12px", color: "#000" }}
          >
            Card Number
          </Typography>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            flexDirection={"column"}
            height={"49px"}
            px={2}
            sx={{ border: "1px solid #999999", borderRadius: "12px" }}
          >
            <CardNumberElement
              options={{
                ...elementOptions,
                placeholder: "XXXX XXXX XXXX XXXX",
              }}
              onChange={(e) => handleElementChange("cardNumber", e)}
            />
          </Stack>
          <FormHelperText sx={{ color: "#DC2626" }}>
            {error === "Your card number is incomplete." ? error : ""}
          </FormHelperText>
        </Stack>

        <Grid2 container spacing={2} sx={{ mt: 2 }}>
          <Grid2 size={{ sm: 6, xs: 12 }}>
            <Typography
              variant="caption"
              sx={{ fontSize: "12px", color: "#000" }}
            >
              Expiry Date
            </Typography>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              flexDirection={"column"}
              height={"49px"}
              px={2}
              sx={{ border: "1px solid #999999", borderRadius: "12px" }}
            >
              <CardExpiryElement
                options={elementOptions}
                onChange={(e) => handleElementChange("expiryDate", e)}
              />
            </Stack>
            <FormHelperText sx={{ color: "#DC2626" }}>
              {error === "Your card's expiration date is incomplete."
                ? error
                : ""}
            </FormHelperText>
          </Grid2>

          <Grid2 size={{ sm: 6, xs: 12 }}>
            <Typography
              variant="caption"
              sx={{ fontSize: "12px", color: "#000" }}
            >
              CVV
            </Typography>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              flexDirection={"column"}
              height={"49px"}
              px={2}
              sx={{ border: "1px solid #999999", borderRadius: "12px" }}
            >
              <CardCvcElement
                options={{
                  ...elementOptions,
                  placeholder: "CVV",
                }}
                onChange={(e) => handleElementChange("cvv", e)}
              />
            </Stack>
            <FormHelperText sx={{ color: "#DC2626" }}>
              {error === "Your card's security code is incomplete."
                ? error
                : ""}
            </FormHelperText>
          </Grid2>
        </Grid2>

        <Button
          type="submit"
          variant="contained"
          sx={{
            mt: 2,
            backgroundColor: "#00A372",
            borderRadius: "15px",
            height: "58px",
          }}
          disabled={!stripe || !elements || completedFields.size < 3}
        >
          <Stack direction={"row"}>
            {paymentLoading ? (
              <CircularProgress color="inherit" size="25px" />
            ) : (
              <>
                <Stack mr={0.3} direction={"row"} alignItems={"center"}>
                  <BiLock size={"24px"} />
                </Stack>
                <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                  Continue
                </Typography>
              </>
            )}
          </Stack>
        </Button>
      </Stack>
    </form>
  );
};

export default CardPaymentView;
