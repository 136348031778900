import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid2,
  Box,
  Card,
  Stack,
  CardContent,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { BiPlusCircle } from "react-icons/bi";
import PaypalOption from "./paymentComponents/PaypalOption";
import StripeMainView from "./paymentComponents/StripeMainView";
import { getCookie } from "../Utils/cookie";

// import { Close } from "@mui/icons-material";

const PaymentPopup = ({ open, handleClose, goToNextSlide, currentPlan }) => {
  const [paymentMethod, setPaymentMethod] = useState("paypal");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const data = getCookie("data");
  return (
    <Dialog
      open={typeof open === "boolean" ? open : true}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <DialogTitle>
        <Box container justifyContent="space-between" alignItems="center">
          {/* <BiCloset /> */}
          <IconButton onClick={handleClose}>
            <BiPlusCircle style={{ transform: "rotate(45deg)" }} />
          </IconButton>

          {/* <Button onClick={handleClose}></Button> */}
        </Box>
        <Box
          sx={{
            display: "grid",
            justifyContent: "center",
          }}
        >
          <Typography
            fontWeight={900}
            fontSize={20}
            textAlign={{ sm: "left", xs: "center" }}
          >
            Select Payment Method
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{ padding: { lg: "20px", md: "16px", sm: "12px", xs: "8px" } }}
      >
        <Grid2
          container
          spacing={2}
          direction={{ sm: "row", xs: "column-reverse" }}
          margin={{ lg: 5, md: 3, sm: 2, xs: 1 }}
        >
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Box
              minWidth={{ lg: "500px", md: "400px", sm: "300px", xs: "100%" }}
              width={"100%"}
              textAlign={{ sm: "left", xs: "center" }}
            >
              <Box sx={{ alignItems: "left" }}>
                <Typography fontWeight={600} fontSize={16}>
                  Your Order Summary
                </Typography>
              </Box>
              <Box
                display={"flex"}
                marginTop={{ lg: 5, md: 4, sm: 3, xs: 2 }}
                justifyContent={{ sm: "", xs: "center" }}

                // width={"100%"}
                //   borderBottom="1px solid gray"
              >
                <Grid2 container sx={{ width: "100%" }}>
                  <Grid2 size={{ xs: 6 }}>
                    <Typography
                      fontWeight={600}
                      fontSize={{ lg: 14, md: 14, sm: 12, xs: 12 }}
                      justifyContent={"left"}
                    >
                      {currentPlan?.name || 'N/A'}
                    </Typography>
                  </Grid2>
                  <Grid2 size={{ xs: 6 }}>
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      justifyContent={"right"}
                    >
                      {currentPlan?.price || '$0'}
                    </Typography>
                  </Grid2>
                </Grid2>
              </Box>

              <Box
                marginTop={{ lg: 2, xs: 1 }}
                borderBottom="1px solid gray"
                width={"70%"}
              ></Box>

              <Box
                marginTop={2}
                // justifyContent="space-between"
                // display={"flex"}
                //   sx={{ alignItems: "left" }}
              >
                <Grid2 container>
                  <Grid2 size={{ xs: 6 }}>
                    <Typography
                      fontSize={{ lg: 18, md: 16, sm: 14, xs: 14 }}
                      fontWeight={600}
                    >
                      Total Today:
                    </Typography>
                  </Grid2>
                  <Grid2 size={{ xs: 6 }}>
                    <Typography
                      fontSize={{ lg: 18, md: 16, sm: 14, xs: 14 }}
                      fontWeight={600}
                    >
                      {currentPlan?.price || '$0'}
                    </Typography>
                  </Grid2>
                </Grid2>
              </Box>
            </Box>
          </Grid2>
          <Grid2
            size={{ xs: 12, sm: 6 }}
            sx={{
              display: { sm: "", xs: "flex" },
              justifyContent: { sm: "", xs: "center" },
            }}
          >
            <Box>
              <Grid2
                container
                spacing={2}
                sx={{
                  backgroundColor: "#D9D9D9",
                  p: 1,
                  borderRadius: "12px",
                }}
              >
                <Grid2 size={{ xs: 6 }}>
                  <Stack
                    mr={1}
                    onClick={() => {
                      setPaymentMethod("paypal");
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: "12px",
                        height: "100%",
                        width: "100%",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        ...(paymentMethod === "paypal"
                          ? {}
                          : { boxShadow: "none", backgroundColor: "inherit" }),
                      }}
                      color={paymentMethod === "card" ? "#fff" : "inherit"}
                    >
                      <CardContent
                        sx={{
                          p: { lg: "20px", md: "15px", sm: "12px", xs: "8px" },
                          "&:last-child": {
                            pb: {
                              lg: "20px",
                              md: "15px",
                              sm: "12px",
                              xs: "8px",
                            },
                          },
                        }}
                      >
                        <img
                          style={{
                            height: "auto",
                            width: "100%",
                            filter:
                              paymentMethod === "paypal"
                                ? "none"
                                : "grayscale(100%)",
                          }}
                          src={
                            paymentMethod === "paypal"
                              ?  `${process.env.REACT_APP_PUBLIC_URL_IMAGE_PNG}/paypal_selected.png`
                              :  `${process.env.REACT_APP_PUBLIC_URL_IMAGE_PNG}/paypal_unselected.png`
                          }
                          alt={"paypal"}
                        />
                      </CardContent>
                    </Card>
                  </Stack>
                </Grid2>
                <Grid2 size={{ xs: 6 }}>
                  <Stack
                    // ml={1}
                    height={"100%"}
                    width={"100%"}
                    onClick={() => {
                      setPaymentMethod("card");
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: "12px",
                        cursor: "pointer",
                        height: "100%",
                        // width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        ...(paymentMethod === "card"
                          ? {}
                          : { boxShadow: "none", backgroundColor: "#D9D9D9" }),
                      }}
                      color={paymentMethod === "card" ? "#fff" : "inherit"}
                    >
                      <CardContent
                        sx={{
                          p: { lg: "20px", md: "15px", sm: "12px", xs: "8px" },
                          "&:last-child": {
                            pb: {
                              lg: "20px",
                              md: "15px",
                              sm: "12px",
                              xs: "8px",
                            },
                          },
                        }}
                      >
                        <Box>
                          <Typography
                            color={
                              paymentMethod === "card" ? "#141432" : "#717171"
                            }
                            fontSize={{ sm: "18px", xs: "14px" }}
                            fontWeight={"600"}
                          >
                            Credit Card
                          </Typography>
                          {!isSmallScreen && (
                            <Stack
                              direction={"row"}
                              justifyContent={"space-evenly"}
                              mt={{ md: 2, sm: 1 }}
                            >
                              <img
                                alt="visa"
                                src={ `${process.env.REACT_APP_PUBLIC_URL_IMAGE_PNG}/visa.png`}
                                style={{
                                  filter:
                                    paymentMethod === "card"
                                      ? "none"
                                      : "grayscale(100%)",
                                }}
                              />
                              <img
                                alt="master"
                                src={ `${process.env.REACT_APP_PUBLIC_URL_IMAGE_PNG}/master.png`}
                                style={{
                                  filter:
                                    paymentMethod === "card"
                                      ? "none"
                                      : "grayscale(100%)",
                                }}
                              />
                              <img
                                alt="mestro"
                                src={ `${process.env.REACT_APP_PUBLIC_URL_IMAGE_PNG}/mestro.png`}
                                style={{
                                  filter:
                                    paymentMethod === "card"
                                      ? "none"
                                      : "grayscale(100%)",
                                }}
                              />
                            </Stack>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Stack>
                </Grid2>
              </Grid2>

              {paymentMethod === "paypal" && (
                <Stack direction={"row"} justifyContent={"center"} mt={2}>
                  <PaypalOption goToNextSlide={goToNextSlide} currentPlan={currentPlan} />
                </Stack>
              )}
              {paymentMethod === "card" && <StripeMainView goToNextSlide={goToNextSlide} currentPlan={currentPlan} />}
            </Box>
          </Grid2>
        </Grid2>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentPopup;
